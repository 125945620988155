<template>
  <div class="home">
    <section class="cover">
      <div class="translucent-gr" />
      <div
        class="background-image-wrapper bg-position-top"
        :style="{
          backgroundImage:
            'url(https://public-assets.naamdeo.org/cdn-cgi/image/width=1440,quality=90,format=auto/images/cover.jpg)',
        }"
      />
      <div class="content">
        <div class="text-content">
          <h1 class="text-3xl font-medium capitalize tracking-wider text-white lg:text-5xl">
            Ascend higher
          </h1>
          <p class="text-shadow-1 mt-4 text-sm tracking-wider text-white lg:mt-8 lg:text-2xl">
            "These hands are full with things<br />holding us back for rather too long. <br />They
            seek to be freed to enable hand-holding<br />towards our highest purpose."
          </p>
          <div class="mt-4 flex flex-wrap gap-8 lg:mt-12">
            <NuxtLink to="https://courses.naamdeo.org/" target="_blank">
              <button class="btn btn-primary">Find Yourself</button>
            </NuxtLink>
            <NuxtLink to="/teachings"
              ><button class="link-white">Timeless Teachings</button></NuxtLink
            >
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="mx-auto px-0 py-12 md:container lg:px-12 lg:py-24">
        <h4 class="text-color-1 px-4 text-center text-4xl font-bold lg:px-0">Timeless Teachings</h4>
        <p class="mt-8 px-4 text-center text-xl lg:px-20">
          Discipline stabilises life, establishes success; but that which rules life is not
          discipline.<br />It is the higher vibration which takes us towards finding our true self.
        </p>
        <div class="mt-12 grid gap-8 lg:grid-cols-5">
          <div v-if="changeYourLifeBlog" class="lg:col-span-3">
            <NuxtLink :to="changeYourLifeBlog?._path">
              <div class="highlight-big">
                <div class="highlight-big--image">
                  <img
                    :src="`https://public-assets.naamdeo.org/cdn-cgi/image/width=800,quality=75,format=auto/images/blog/${changeYourLifeBlog.image}`"
                    alt="post-image"
                  />
                </div>
                <div class="highlight-big--content">
                  <h4 class="text-2xl font-medium capitalize">
                    {{ changeYourLifeBlog.title }}
                  </h4>
                  <div v-for="(child, index) in changeYourLifeBlog?.excerpt?.children" :key="index">
                    <p
                      v-if="(child as any).tag === 'p'"
                      :class="`mt-4 text-sm ${index > 1 ? 'lg:block' : ''}`"
                    >
                      {{ (child as any).children[0].value }}
                    </p>
                  </div>
                </div>
              </div>
            </NuxtLink>
          </div>
          <div class="lg:col-span-2">
            <div class="grid grid-cols-1 gap-8">
              <NuxtLink :to="opportunityBlog?._path">
                <div v-if="opportunityBlog" class="highlight-small">
                  <div class="highlight-small--image">
                    <img
                      :src="`https://public-assets.naamdeo.org/cdn-cgi/image/width=800,quality=75,format=auto/images/blog/${opportunityBlog.image}`"
                      alt="post-image"
                    />
                  </div>
                  <div class="highlight-small--content">
                    <h4 class="text-lg font-medium capitalize">
                      {{ opportunityBlog.title }}
                    </h4>
                    <p class="mt-2 text-sm">
                      {{ opportunityBlog.description }}
                    </p>
                  </div>
                </div>
              </NuxtLink>
              <NuxtLink :to="progressBlog?._path">
                <div v-if="progressBlog" class="highlight-small">
                  <div class="highlight-small--image">
                    <img
                      :src="`https://public-assets.naamdeo.org/cdn-cgi/image/width=800,quality=75,format=auto/images/blog/${progressBlog.image}`"
                      alt="post-image"
                    />
                  </div>
                  <div class="highlight-small--content">
                    <h4 class="text-lg font-medium capitalize">
                      {{ progressBlog.title }}
                    </h4>
                    <p class="mt-2 text-sm">{{ progressBlog.description }}</p>
                  </div>
                </div>
              </NuxtLink>
            </div>
          </div>
        </div>
        <div class="text-center">
          <NuxtLink to="/teachings">
            <button class="btn btn-primary mt-8">Discover More</button>
          </NuxtLink>
        </div>
      </div>
    </section>
    <section id="quotes" class="section-bg-1">
      <div class="mx-auto px-4 py-12 md:container lg:px-12 lg:py-24">
        <Swiper
          :speed="2000"
          :autoplay="{
            delay: 10000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }"
          :modules="swiperModules"
          :pagination="{ clickable: true }"
          :scrollbar="{ draggable: true }"
        >
          <SwiperSlide v-for="quote in quotesData" :key="quote._id">
            <div class="quote">
              <div class="content">
                <div class="review">
                  <ContentRenderer class="nuxt-content" :value="quote" />
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';

const { data: dataLife } = await useAsyncData('featured-blogs', () =>
  queryContent('/blog/what-could-actually-change-your-life-vision-of-life').find()
);
const { data: dataOpportunity } = await useAsyncData('opportunity-blog', () =>
  queryContent('/blog/life-is-it-an-opportunity-or-is-it-a-tragedy').find()
);
const { data: dataProgress } = await useAsyncData('law-of-progress-blog', () =>
  queryContent('/blog/the-simple-law-to-make-progress').find()
);

const changeYourLifeBlog = dataLife.value?.at(0);
const opportunityBlog = dataOpportunity.value?.at(0);
const progressBlog = dataProgress.value?.at(0);

const { data: quotesData } = await useAsyncData('quotes', () =>
  queryContent('/quotes').where({ slider: true }).sort({ order: 1, $numeric: true }).find()
);
const swiperModules = [Autoplay, Pagination];

useHead({
  title: 'Home',
});
</script>

<style lang="scss" scoped>
.home {
  .cover {
    width: 100%;
    height: 100vh;
    position: relative;
    .bg-position-top {
      background-position-y: top;
    }
    .content {
      height: 100%;
      padding-left: 8rem;
      z-index: 3;
      position: relative;
      width: 100%;
      .text-content {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 50%;
      }
    }
    @media (max-width: 767px),
      (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
      height: 300px;
      .content {
        padding: 1rem;
        position: absolute;
        height: auto;
        bottom: 0;
        .text-content {
          position: relative;
          width: 100%;
          top: unset;
          transform: none;
          .text-shadow-1 {
            text-shadow: 0px 2px rgb(0 0 0 / 80%);
          }
        }
      }
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
      height: 500px;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
      .content {
        padding-left: 4rem;
      }
    }
  }
  .highlight-big {
    background: #f1f5f5;
    height: 100%;
    -webkit-filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.3));
    filter: drop-shadow(0 0.2px 0.5px rgba(0, 0, 0, 0.3));
    cursor: pointer;
    &:hover {
      -webkit-filter: drop-shadow(2px 2px 20px rgba(0, 0, 0, 0.15));
      filter: drop-shadow(2px 2px 20px rgba(0, 0, 0, 0.15));
    }
    &--image {
      width: 100%;
      aspect-ratio: 16/9;
      img {
        width: 100%;
        height: 100%;
      }
    }
    &--content {
      background-color: #f1f5f5;
      padding: 14px 24px;
      p {
        overflow: auto;
      }
    }
  }
  .highlight-small {
    background: #f1f5f5;
    -webkit-filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.3));
    filter: drop-shadow(0 0.2px 0.5px rgba(0, 0, 0, 0.3));
    cursor: pointer;
    &:hover {
      -webkit-filter: drop-shadow(2px 2px 20px rgba(0, 0, 0, 0.15));
      filter: drop-shadow(2px 2px 20px rgba(0, 0, 0, 0.15));
    }
    &--image {
      width: 100%;
      aspect-ratio: 16/9;
      img {
        width: 100%;
        height: 100%;
      }
    }
    &--content {
      background-color: #f1f5f5;
      padding: 14px 24px;
      p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }
  @media (max-width: 767px),
    (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
    .highlight-big,
    .highlight-small {
      &--content {
        p {
          overflow: auto;
          text-overflow: unset;
          display: block;
        }
      }
    }
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
    .highlight-small {
      &--content {
        p {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
  #quotes {
    .quote {
      .content {
        position: relative;
        padding: 24px 40px;
        cursor: pointer;
        .review {
          padding: 40px;
          text-align: center;
          font-size: 1.5rem;
          :deep(.nuxt-content) {
            p {
              margin-bottom: 16px;
            }
            strong {
              color: $color-1;
            }
          }
        }
        &:before {
          content: '';
          background-image: url('https://public-assets.naamdeo.org/images/quotes.svg');
          background-size: 50%;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          height: 100px;
          width: 100px;
          background-repeat: no-repeat;
          opacity: 0.2;
          background-position-x: center;
        }
      }
      @media (max-width: 767px),
        (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
        .content {
          padding: 24px 0px;
          .review {
            padding: 40px 8px;
            text-align: center;
            font-size: 1rem;
          }
        }
      }
    }
    :deep(.swiper-pagination-bullet) {
      width: 10px;
      height: 10px;
      opacity: 1;
      background: rgba(0, 0, 0, 0.2);
      margin: 0 10px;
    }
    :deep(.swiper-pagination-bullet-active) {
      background: rgba(0, 0, 0, 0.7);
    }
  }
}
</style>
